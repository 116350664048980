import "./App.css";
import Navbar from "./components/Navbar";
import Register from "./components/Register";
import Login from "./components/Login";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import ConfirmEmailPage from "./components/ConfirmEmailPage";
import MyProjects from "./components/MyProjects";
import {FormProvider}  from "./context/FormContext"
import UserInputForm from "./components/InputComponents/UserInputForm";
import UserInputFormBeta from "./components/InputComponents/UserInputFormBeta";
function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/confirmemail" element={<ConfirmEmailPage />} />
        <Route path="/myprojects" element={<MyProjects />} />
      </Routes>
      <FormProvider>
        <Routes>
          <Route path="/input" element={<UserInputFormBeta />} />
        </Routes>
      </FormProvider>
    </div>
  );
}

export default App;
