import React, { useContext } from "react";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col } from "antd";
import Carbo2oadTitle from "./Carbo2oadTitle";
import AuthContext from "../context/AuthContext";
import BackgroundSlideshow from "./BackgroundSlideshow";
import CarboloadTitleAnimation from "./CarboloadTitleAnimation";

const { Text } = Typography;

const Home = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const goToSignUp = () => {
    navigate("signup");
  };

  const goToCalculationPage = () => {
    navigate("welcome");
  };

  const goToModule1Page = () => {
    navigate("material-info");
  };

  const goToSteelPage = () => {
    navigate("steel-reinforcement-info");
  };

  const textStyle = {
    // position: "absolute",
    top: "50%", // Adjust vertical positioning as needed
    left: "50%", // Adjust horizontal positioning as needed
    transform: "translate(-50%, -50%)", // Center the text horizontally and vertically
    color: "black", // Set the text color
    textAlign: "center", // Center the text within the container
    fontSize: "18px", // Set the font size of the text
    // Set the font weight of the text
  };

  return (
    <div>
      <Row style={{ height: "50vh" }}>
        <Col span={24}>
          <BackgroundSlideshow />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ height: "80px" }}>
              <CarboloadTitleAnimation fontSize={40} />
            </div>

            <div>
              <Text italic style={textStyle}>
                Construction sector | Cement Industry | Concrete Industry
              </Text>
            </div>

            <div style={{ marginTop: 15 }}>
              {!user && (
                <Button type="primary" size="large" onClick={goToSignUp}>
                  Sign Up
                </Button>
              )}
              {user && (
                <div>
                  <Button
                    style={{ margin: "10px" }}
                    type="primary"
                    size="large"
                    onClick={() => navigate("input")}
                  >
                    Start New Project
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
