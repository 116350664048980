import { useState, useEffect } from "react";
import { Table, Input, Button, message, Typography} from "antd";
import useFormContext from "../../../hooks/useFormContext";
const {Title} = Typography;
const SelectedMaterials = ({ selectedMaterials }) => {
  const [materials, setMaterials] = useState(selectedMaterials);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const { concreteInfo, setConcreteInfo } = useFormContext();
  const [messageApi, contextHolder] = message.useMessage();

  const handleCostChange = (id, value) => {
    const updatedMaterials = materials.map((material) => {
      if (material.id === id) {
        return { ...material, cost: value };
      }
      return material;
    });
    setMaterials(updatedMaterials);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Compressive Strength (PSI)",
      dataIndex: "compressiveStrength",
      key: "compressiveStrength",
    },
    {
      title: (
        <span>
          GWP (Kg Co<sub>2</sub>-eq / yd<sup>3</sup>)
        </span>
      ),
      dataIndex: "gwp",
      key: "gwp",
    },
    {
      title: "Category",
      dataIndex: "materialCategory",
      key: "materialCategory",
    },
    {
      title: <span> Cost (USD / yd<sup>3</sup>) </span>,
      dataIndex: "cost",
      key: "cost",
      render: (text, record) => (
        <Input
          type="number"
          value={record.cost}
          onChange={(e) => handleCostChange(record.id, e.target.value)}
        />
      ),
    },
  ];

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Materials saved. Click Next to proceed',
    });
  };

  useEffect(() => {
    setMaterials(selectedMaterials);
  }, [selectedMaterials]);

  useEffect(() => {
    const allCostsAdded = materials.every(
      (material) => material.cost != null && material.cost !== ""
    );
    setIsButtonEnabled(allCostsAdded);
  }, [materials]);

  const finaliseMaterials = () => {
    console.log("Finalised Materials:", materials);
    setConcreteInfo(materials);
    success();
  };

  return (
    <div style={{ margin: 10 }}>
      {contextHolder}
      <Title level={3} style={{textAlign: "left"}}>Review and Finalise</Title>
      <Table dataSource={materials} columns={columns} pagination={false} />
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        <Button
          type="primary"
          onClick={finaliseMaterials}
          disabled={!isButtonEnabled}
        >
          Finalise Materials
        </Button>
      </div>
    </div>
  );
};

export default SelectedMaterials;
