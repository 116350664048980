import { createContext, useState, useEffect } from "react";

const FormContext = createContext({});

export const FormProvider = ({ children }) => {
  const [concreteInfo, setConcreteInfo] = useState([]);

  const [row, setRow] = useState([]);

  const [floorPlanInput, setFloorPlanInput] = useState([]);

  const [groupOfFloors, setGroupOfFloors] = useState([]);

  const [designGroups, setDesignGroups] = useState([]);

  const [mixProduct, setMixProduct] = useState([]);

  const [materialsInputPereference, setMaterialsInputPereference] = useState(1);

  const [floorPlanInfo, setFloorPlanInfo] = useState();

  const [shapes, setShapes] = useState([]);

  const [shearReinforcement, setShearReinforcement] = useState([]);

  const [swayType, setSwayType] = useState("Sway");

  const [momentMagnifier, setMomentMagnifier] = useState(1.35);

  const [steel, setSteel] = useState();

  const [combinations, setCombinations] = useState([]);

  const [floorValues, setFloorValues] = useState([]);

  const [buildingValues, setBuildingValues] = useState([]);

  const [optimalDesigns, setOptimalDesigns] = useState([]);

  const [selectedMaterials, setSelectedMaterials] = useState([]);

  const [searchResponse, setSearchResponse] = useState([]);

  const [error, setError] = useState();

  const [formworkCost, setFormworkCost] = useState();

  const [secondOptimizationFilterParams, setSecondOptimizationFilterParams] =
    useState([]);

  const [floorLevelValuesSecondStage, setFloorLevelValuesSecondStage] =
    useState([]);
  const [buildingLevelValuesSecondStage, setBuildingLevelValuesSecondStage] =
    useState([]);

  useEffect(() => {
    console.log("Some data has changed, this is form global ", row);
  }, row);

  return (
    <FormContext.Provider
      value={{
        concreteInfo,
        setConcreteInfo,
        row,
        setRow,
        floorPlanInput,
        setFloorPlanInput,
        groupOfFloors,
        setGroupOfFloors,
        designGroups,
        setDesignGroups,
        floorPlanInfo,
        setFloorPlanInfo,
        mixProduct,
        setMixProduct,
        shapes,
        setShapes,
        shearReinforcement,
        setShearReinforcement,
        swayType,
        setSwayType,
        momentMagnifier,
        setMomentMagnifier,
        steel,
        setSteel,
        combinations,
        setCombinations,
        floorValues,
        setFloorValues,
        buildingValues,
        setBuildingValues,
        error,
        setError,
        optimalDesigns,
        setOptimalDesigns,
        selectedMaterials,
        setSelectedMaterials,
        searchResponse,
        setSearchResponse,
        formworkCost,
        setFormworkCost,
        secondOptimizationFilterParams,
        setSecondOptimizationFilterParams,
        floorLevelValuesSecondStage,
        setFloorLevelValuesSecondStage,
        buildingLevelValuesSecondStage,
        setBuildingLevelValuesSecondStage,
        materialsInputPereference,
        setMaterialsInputPereference,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
