import { UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Layout, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Carbo2oadTitle from "./Carbo2oadTitle";
import "../App.css";
const { Header} = Layout;
const { Title } = Typography;

const Navbar = () => {
  const { user, signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClick = () => {
    signOut();
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const items = [
    {
      label: <a href="/">Profile</a>,
      key: "home",
    },
    {
      label: (
        <a href="#" onClick={handleClick}>
          Sign Out
        </a>
      ),
      key: "signout",
    },
    {
      label: <a href="myprojects">My Projects</a>,
      key: "myProjects",
    },
    
  ];
  return (
    <Layout >
      <Header
        style={{
          display: "flex",
          alignItems: "center",        
        }}
      >
        <div style={{ marginBottom: "10px"}}>
          <a href="/">
            <Carbo2oadTitle color="white" fontSize={30}/>
          </a>
        </div>
        {/* <Menu
          theme="dark"
          mode="horizontal"
          style={{ marginLeft: "auto", color: "white" }}
          items={items}
        /> */}
        {user && (
          <Dropdown
            menu={{
              items,
            }}
            placement="bottom"
            arrow
          >
            <Button
              style={{ marginLeft: "auto" }}
              icon={<UserOutlined />}
            ></Button>
          </Dropdown>
        )}
        {!user && (
          <Button style={{ marginLeft: "auto" }} onClick={goToLogin}>
            Login
          </Button>
        )}
      </Header>
    </Layout>
  );
};

export default Navbar;
