import React, { useState, useEffect } from "react";
import { Typography } from "antd";

const { Title } = Typography;

const CarboloadTitleAnimation = ({ color, fontSize }) => {
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setToggle(t => !t);
        }, 1500);

        return () => clearInterval(interval);
    }, []);

    const colors = {
        color: color       
    };

    const size = {
        fontSize: fontSize
    };

    return (
      <div>
        <Title style={size}>
          <span style={{ color: 'olive' }}>C</span>
          <span style={colors}>arbo</span>
          <span style={{ color: 'olive' }}>O</span>
          {toggle ? <span style={{color: 'olive'}}><sub>2</sub></span> : <span style={{color: 'olive'}}>L</span>}
          <span style={colors}>oad</span>
        </Title>
      </div>
    );
};

export default CarboloadTitleAnimation;
