const defaultValues = {
  concretes: [
    {
      subcategory: "UHPC 1",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 1,
      description: "8 sack, 3000 psi",
      compressiveStrength: 22930,
      gwp: 1675,
      cost: 1012,
      materialCategory: "UHPC",
    },
    {
      subcategory: "UHPC 2",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 2,
      description: "8 sack, 3000 psi",
      compressiveStrength: 22451,
      gwp: 1280,
      cost: 1019,
      materialCategory: "UHPC",
    },
    {
      subcategory: "UHPC 3",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 3,
      description: "8 sack, 3000 psi",
      compressiveStrength: 17549,
      gwp: 1653,
      cost: 1114,
      materialCategory: "UHPC",
    },
    {
      subcategory: "UHPC 4",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 4,
      description: "8 sack, 3000 psi",
      compressiveStrength: 29001,
      gwp: 1332,
      cost: 1379,
      materialCategory: "UHPC",
    },
    {
      subcategory: "UHPC 5",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 5,
      description: "8 sack, 3000 psi",
      compressiveStrength: 27847,
      gwp: 1300,
      cost: 1060,
      materialCategory: "UHPC",
    },
    {
      subcategory: "UHPC 6",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 6,
      description: "8 sack, 3000 psi",
      compressiveStrength: 29152,
      gwp: 1328,
      cost: 1063,
      materialCategory: "UHPC",
    },
    //----------
    {
      subcategory: "UHPC 7",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 7,
      description: "8 sack, 3000 psi",
      compressiveStrength: 25381,
      gwp: 1204,
      cost: 1064,
      materialCategory: "UHPC",
    },
    {
      subcategory: "UHPC 8",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 8,
      description: "8 sack, 3000 psi",
      compressiveStrength: 24801,
      gwp: 1208,
      cost: 1053,
      materialCategory: "UHPC",
    },
    {
      subcategory: "UHPC 9",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 9,
      description: "8 sack, 3000 psi",
      compressiveStrength: 27557,
      gwp: 1303,
      cost: 1073,
      materialCategory: "UHPC",
    },
    {
      subcategory: "UHPC 10",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 10,
      description: "8 sack, 3000 psi",
      compressiveStrength: 23641,
      gwp: 1360,
      cost: 1123,
      materialCategory: "UHPC",
    },
    {
      subcategory: "HPC 1",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 11,
      description: "8 sack, 3000 psi",
      compressiveStrength: 13778,
      gwp: 811,
      cost: 336,
      materialCategory: "UHPC",
    },
    {
      subcategory: "HPC 2",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 12,
      description: "8 sack, 3000 psi",
      compressiveStrength: 10848,
      gwp: 1334,
      cost: 334,
      materialCategory: "HPC",
    },
    {
      subcategory: "HPC 3",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 13,
      description: "8 sack, 3000 psi",
      compressiveStrength: 15229,
      gwp: 892,
      cost: 327,
      materialCategory: "HPC",
    },
    {
      subcategory: "HPC 4",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 14,
      description: "8 sack, 3000 psi",
      compressiveStrength: 11254,
      gwp: 626,
      cost: 201,
      materialCategory: "HPC",
    },
    {
      subcategory: "HPC 5",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 15,
      description: "8 sack, 3000 psi",
      compressiveStrength: 12197,
      gwp: 1169,
      cost: 283,
      materialCategory: "HPC",
    },
    {
      subcategory: "HPC 6",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 16,
      description: "8 sack, 3000 psi",
      compressiveStrength: 8745,
      gwp: 643,
      cost: 127,
      materialCategory: "HPC",
    },
    {
      subcategory: "HPC 7",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 17,
      description: "8 sack, 3000 psi",
      compressiveStrength: 7222,
      gwp: 648,
      cost: 126,
      materialCategory: "HPC",
    },
    {
      subcategory: "HPC 8",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 18,
      description: "8 sack, 3000 psi",
      compressiveStrength: 9122,
      gwp: 701,
      cost: 188,
      materialCategory: "HPC",
    },
    {
      subcategory: "HPC 9",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 19,
      description: "8 sack, 3000 psi",
      compressiveStrength: 11936,
      gwp: 752,
      cost: 197,
      materialCategory: "HPC",
    },
    {
      subcategory: "HPC 10",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 20,
      description: "8 sack, 3000 psi",
      compressiveStrength: 10152,
      gwp: 1474,
      cost: 396,
      materialCategory: "HPC",
    },
    {
      subcategory: "NSC 1",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 21,
      description: "8 sack, 3000 psi",
      compressiveStrength: 5047,
      gwp: 672,
      cost: 125,
      materialCategory: "NSC",
    },
    {
      subcategory: "NSC 2",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 22,
      description: "8 sack, 3000 psi",
      compressiveStrength: 3626,
      gwp: 435,
      cost: 111,
      materialCategory: "NSC",
    },
    {
      subcategory: "NSC 3",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 23,
      description: "8 sack, 3000 psi",
      compressiveStrength: 3350,
      gwp: 404,
      cost: 109,
      materialCategory: "NSC",
    },
    {
      subcategory: "NSC 4",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 24,
      description: "8 sack, 3000 psi",
      compressiveStrength: 3132,
      gwp: 376,
      cost: 108,
      materialCategory: "NSC",
    },
    {
      subcategory: "NSC 5",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 25,
      description: "8 sack, 3000 psi",
      compressiveStrength: 6164,
      gwp: 588,
      cost: 107,
      materialCategory: "NSC",
    },
    {
      subcategory: "NSC 6",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 26,
      description: "8 sack, 3000 psi",
      compressiveStrength: 4481,
      gwp: 410,
      cost: 96,
      materialCategory: "NSC",
    },
    {
      subcategory: "NSC 7",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 27,
      description: "8 sack, 3000 psi",
      compressiveStrength: 3016,
      gwp: 350,
      cost: 92,
      materialCategory: "NSC",
    },
    {
      subcategory: "NSC 8",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 28,
      description: "8 sack, 3000 psi",
      compressiveStrength: 4873,
      gwp: 409,
      cost: 111,
      materialCategory: "NSC",
    },
    {
      subcategory: "PLC 1",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 29,
      description: "8 sack, 3000 psi",
      compressiveStrength: 5235,
      gwp: 378,
      cost: 106,
      materialCategory: "PLC",
    },
    {
      subcategory: "PLC 2",
      manufacturer: "Graniteock",
      name: "Graniteock",
      plant: "Del monte boulevard",
      id: 30,
      description: "8 sack, 3000 psi",
      compressiveStrength: 5308,
      gwp: 378,
      cost: 106,
      materialCategory: "PLC",
    },
  ],

  steelReinforcement: {
    subcategory: "Longitudinal steel rebars",
    steelGrade: 60000,
    gwp: 7877,
    cost: 5625,
  },

  rebarInfo: {
    3: { size: 3, diameter: '3/8"', pricePerFoot: '$0.40 - $1.25', pricePerTon: '$1,450 - $2,000', pricePerTonAverage: 1700, usage: 'Patio, Sidewalk, ...' },
    4: { size: 4, diameter: '3/8"', pricePerFoot: '$0.40 - $1.25', pricePerTon: '$1,450 - $2,000', pricePerTonAverage: 1701, usage: 'Patio, Sidewalk, ...' },
    5: { size: 5, diameter: '3/8"', pricePerFoot: '$0.40 - $1.25', pricePerTon: '$1,450 - $2,000', pricePerTonAverage: 1702, usage: 'Patio, Sidewalk, ...' }
  },

  designGroups: [
    {
      groupName: "A",
      columnGroups: "Interior Columns",
      designGroup: "Int-Col-Floor-1",
      floorRange: [1, 1],
      length: 13.3,
      numberOfColumns: 8,
      boundaryConditions: "k=1",
      unspportedLength: 13.3,
    },
    {
      groupName: "A",
      columnGroups: "Exterior Columns",
      designGroup: "Ext-Col-Floor-1",
      floorNumber: 1,
      floorRange: [1, 1],
      length: 13.3,
      numberOfColumns: 16,
      boundaryConditions: "k=1",
      unspportedLength: 13.3,
    },
    {
      groupName: "A",
      columnGroups: "Interior Columns",
      designGroup: "Int-Col-Floor-2",
      floorNumber: 1,
      floorRange: [2, 2],
      length: 10.3,
      numberOfColumns: 8,
      boundaryConditions: "k=1",
      unspportedLength: 10.3,
    },
    {
      groupName: "A",
      columnGroups: "Exterior Columns",
      designGroup: "Ext-Col-Floor-2",
      floorNumber: 1,
      floorRange: [2, 2],
      length: 10.3,
      numberOfColumns: 16,
      boundaryConditions: "k=1",
      unspportedLength: 10.3,
    },
    {
      groupName: "B",
      columnGroups: "Interior Columns",
      designGroup: "Int-Col-Floor-3-12",
      floorNumber: 1,
      floorRange: [3, 12],
      length: 10.3,
      numberOfColumns: 8,
      boundaryConditions: "k=1",
      unspportedLength: 10.3,
    },
    {
      groupName: "A",
      columnGroups: "Exterior Columns",
      designGroup: "Ext-Col-Floor-3-12",
      floorNumber: 1,
      floorRange: [3, 12],
      length: 10.3,
      numberOfColumns: 16,
      boundaryConditions: "k=1",
      unspportedLength: 10.3,
    },
  ],
};

export default defaultValues;
