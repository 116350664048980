import React from "react";
import { Typography } from "antd";
const { Title } = Typography;


const Carbo2oadTitle = ({color, fontSize}) => {
    const colors = {
        color: color       
    }
    const size = {
        fontSize: fontSize
    }
    return (
      <div>
        <Title style={size}>
          <span style={{ color: 'olive' }}>C</span>
          <span style={colors}>arbo</span>
          <span style={{ color: 'olive' }}>O</span>
          <span style={{color: 'olive'}}><sub>2</sub></span>
          <span style={colors}>oad</span>
        </Title>
      </div>
    );
  };

  export default Carbo2oadTitle;