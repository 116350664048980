import { Table, Carousel, Popover } from "antd";
import useFormContext from "../../hooks/useFormContext";
import columnHeadings from "../Constants/ColumnHeadings";
import { useRef, useState } from "react";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import square from "../../assets/sqaure.jpg";
import rectangle from "../../assets/rectangle.jpg";
import circle from "../../assets/circle.jpg";
const { Title } = Typography;
const SummaryTable = () => {
  const { designGroups } = useFormContext();
  const carouselRef = useRef();
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  const handleNext = () => {
    carouselRef.current.next();
    setCurrentGroupIndex(
      (currentGroupIndex + 1 + designGroups.length) % designGroups.length
    );
  };
  const handlePrevious = () => {
    carouselRef.current.prev();
    setCurrentGroupIndex(
      (currentGroupIndex - 1 + designGroups.length) % designGroups.length
    );
  };

  const getImageForDescription = (row) => {
    if(row.dTrial)
      return circle
    if(row.hTrial == row.bTrial)
      return square
    return rectangle;
  };


  const generateTitle = (row) => {
    if(row.dTrial) {
      const d = Math.ceil(row.dTrial * 39.37);
      return d + " in"
    }
    if(row.hTrial == row.bTrial) {
      const a = Math.ceil(row.hTrial * 39.37);
      return a + " in x " + a + " in";
    }
    else {
      const b = Math.ceil(row.hTrial * 39.37);
      const l = Math.ceil(row.bTrial * 39.37);
      return l + " in x " + b + " in";
    }
  }
  const columns = [
    {
      title: "Mix Design #",
      dataIndex: "product",
      key: "product",
    },
    columnHeadings.concreteCost,
    columnHeadings.concreteGwp,
    {
      title: "Column Description",
      dataIndex: "description",
      key: "description",
      render: (text, row) => (
        <Popover
          content={<img src={getImageForDescription(row)} style={{ width: 200 }} />}
          title={generateTitle(row)}
        >
          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
            {text}
          </span>
        </Popover>
      ),
    },
    columnHeadings.iC,
    columnHeadings.iSw,
    columnHeadings.iTotal,
    columnHeadings.dollarC,
    columnHeadings.dollarSw,
    columnHeadings.dollarFw,
    columnHeadings.dollarTotal
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LeftCircleOutlined
          style={{ fontSize: "30px" }}
          onClick={handlePrevious}
        />
        <Title
          level={4}
          style={{ marginLeft: 10, marginRight: 10, paddingBottom: 15 }}
        >
          {" "}
          Design Group {designGroups[currentGroupIndex].designGroup}
        </Title>
        <RightCircleOutlined
          style={{ fontSize: "30px" }}
          onClick={handleNext}
        />
      </div>
      <Carousel ref={carouselRef}>
        {designGroups.map((designGroup, index) => (
          <div>
            <Table
              dataSource={designGroup.combinations}
              columns={columns}
              pagination={true}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SummaryTable;
