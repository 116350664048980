import React from "react";
import { Carousel } from "antd";

import image_4 from "../assets/image_4.jpg";
import image_8 from "../assets/image_8.jpg";

const BackgroundSlideshow = () => {
  const images = [image_4, image_8];

  const carouselStyle = {
    width: "100%", // Use the full width of the screen
    height: "50vh", // Use one-third of the height of the screen
    overflow: "hidden", // Ensure the carousel does not overflow its container
    marginTop: 5,
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "contain", // Make the image cover the entire container
    opacity: 0.8,
    transform: "transform 3s ease",
  };

  const textStyle = {
    position: "absolute",
    top: "50%", // Adjust vertical positioning as needed
    left: "50%", // Adjust horizontal positioning as needed
    transform: "translate(-50%, -50%)", // Center the text horizontally and vertically
    color: "white", // Set the text color
    textAlign: "center", // Center the text within the container
    fontSize: "30px", // Set the font size of the text
    fontWeight: "bold", // Set the font weight of the text
    
  };

  return (
    <div style={carouselStyle}>
      <Carousel
        autoplay
        effect="fade"
        className="carousel"
        autoplaySpeed={4000}
        
      >
        {images.map((image) => (
          <div className="slide">
            <img src={image} style={imageStyle} />
          </div>
        ))}
      </Carousel>
      <div style={textStyle}>
        {/* Add your text here */}
        
        Software for integrated optimization strategies to <br /> decarbonize
        concrete building design
      </div>  
    </div>
  );
};

export default BackgroundSlideshow;
